import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Flex,
  Icon,
  Text,
  useBreakpointValue,
  Button,
  VStack,
  Divider,
  Box,
  Image,
} from "@chakra-ui/react";
import useUserDisplayName from "components/hooks/useUserDisplayName";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { auth, db } from "config/firebase";
import React from "react";
import { FaRegBell } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import menu from "../../assets/img/dashboards/menu_header.png"
import menu2x from "../../assets/img/dashboards/header_menu2x.png"

const Header = ({brandText,onOpen}) => {
  const { displayName, isLoading } = useUserDisplayName(db, auth);
  const isMobile = useBreakpointValue({ base: true, md: false });

  if (isLoading) return <div>Loading...</div>;

  return (
    
    // <Flex
    //   // alignItems="center"
    //   // justifyContent="space-between"
    //   // p="4"
    //   // bg={{ base: "white", md: "transparent" }}
    //   // boxShadow={{ base: "md", md: "none" }}
      
    // >
      <>      {/* Mobile View: Only Hamburger Icon and Avatar */}
      <Flex display={{ base: "flex", md: "none" }} w="100%" justifyContent="space-between">
        {/* <HamburgerIcon fontSize="xl" onClick={onOpen} /> */}
        <Image src={menu2x} onClick={onOpen} h={"14"}/>
        <Avatar name={displayName} />
      </Flex>

      {/* Desktop View: Full Header Layout without Hamburger Icon */}
      <Flex display={{ base: "none", md: "flex" }} alignItems="center" justifyContent="space-between" w="100%">
        <Text fontWeight="bold" color="#1b2559">
          {brandText}
        </Text>
        <Flex alignItems="center">
          <SearchBar mr="1rem"/>
          <Icon mr="1rem" as={MdOutlineEmail} />
          <Icon mr="1.5rem" as={FaRegBell} />
          <Avatar mr="1rem" name={displayName} />
          <Flex flexDirection="column">
            <Text mr="1rem" fontWeight="bold">
              {displayName} 
            </Text>
            <Text fontSize="sm">CRB</Text>
          </Flex>
        </Flex>
      </Flex>
      </>
   
  );
};

export default Header;
