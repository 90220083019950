import {
  Avatar,
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Image from "views/admin/clintProfile/assets/avatar1.png";
import Personal from "./components/personal";
import BusinessInfo from "./components/businessInfo";
import Verification from "./components/verification";
import Services from "./components/services";
import Goals from "./components/goal";
import useUserDisplayName from "components/hooks/useUserDisplayName";
import { db } from "config/firebase";
import { auth } from "config/firebase";
import { getDownloadURL, uploadBytes } from "firebase/storage";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { ref } from "firebase/database";
import { storage } from "config/firebase";
import { useMediaQuery } from "@mui/material";
import personal_icon from "../clintProfile/assets/Personal.png"
import businessInfo_icon from "../clintProfile/assets/Business_Info.png"
import goal_icon from "../clintProfile/assets/Goals.png"
import arrow_icon from "../clintProfile/assets/ooui_next-ltr.png"
import { transform } from "framer-motion";

const Profile = () => {
  const { displayName, uid } = useUserDisplayName(db, auth);
  const [profilePicture, setProfilePicture] = useState("");
  const [userId, setUserId] = useState(uid);
  const [userData, setUserData] = useState({});
  const isMobile=useMediaQuery("(max-width:640px)")
  const [personalPopup,setPersonalPopup]=useState(false);
  const [businessInfoPopup,setBusinessInfoPopup]=useState(false);
  const [goalPopup,setGoalPopup]=useState(false);

  const handlePersonalPopup=()=>{
    if(personalPopup===false){
      setPersonalPopup(true);
    }else{
      setPersonalPopup(false);
    }
  }

  const handleBusinessInfoPopup=()=>{
    if(businessInfoPopup===false){
      setBusinessInfoPopup(true);
    }else{
      setBusinessInfoPopup(false);
    }
  }

  const handleGoalPopup=()=>{
    if(goalPopup===false){
      setGoalPopup(true);
    }else{
      setGoalPopup(false);
    }
  }



  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        const userDoc = await getDoc(doc(db, "users", userId));
        if (userDoc.exists()) {
          setUserData(userDoc.data());
          setProfilePicture(userDoc.data().profilePicture || "");
        }
      }
    };

    fetchUserData();
  }, [userId]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const storageRef = ref(storage, `profilePictures/${file.name}`);
    await uploadBytes(storageRef, file);

    // Get the download URL for the uploaded file
    const downloadUrl = await getDownloadURL(storageRef);
    setProfilePicture(downloadUrl);

    // Update the user's profile picture in Firestore
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, { profilePicture: downloadUrl });

    // Optionally, clear the input field after successful upload
    event.target.value = "";
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, userData);
  };

  return (
    <div className="d-flex flex-row mb-3">
      {isMobile?<Flex flexDirection="column" alignItems="center">
        <Flex flexDirection="column">
          <label for="actual-btn" style={{cursor:"pointer"}}><Avatar size={isMobile?"xl":"2xl"} name={displayName} src={profilePicture} /></label>
          <input
            type="file"
            id="actual-btn"
            hidden
            onChange={handleFileChange}
            style={{border:"2px solid black"}}
          />
         { /*<label
            for="actual-btn"
            style={{
              marginTop: "0.5rem",
              backgroundColor: "#407BFF",
              color: "white",
              padding: "0.5rem",
              borderRadius: "1rem",
              cursor: "pointer",
              textAlign: "center",
            }}
          >
            Edit
          </label>*/}
        </Flex>
          
        <div style={{ textAlign:"center" }}>
          <h1 style={{ fontSize: "18px", fontWeight: "bold" }}>
            {displayName}
          </h1>
          <p style={{fontSize:"12px"}}>Profile Completion : 100%</p>
        </div>
      </Flex>:<Flex>
        <Flex flexDirection="column">
        <label for="actual-btn" style={{cursor:"pointer"}}><Avatar size="2xl" name={displayName} src={profilePicture} /></label>
          <input
            type="file"
            id="actual-btn"
            hidden
            onChange={handleFileChange}
          />
  
        </Flex>
          
        <div style={{ marginLeft: "1rem" }}>
          <h2 style={{ fontSize: "2rem", fontWeight: "bold" }}>
            {displayName}
          </h2>
          <p>Profile Completion : 100%</p>
        </div>
      </Flex>}
      
        
      {isMobile?<div style={{display:"flex",flexDirection:"column",height:"max-content",width:"100%",alignItems:"center"}}>
      <div style={{boxShadow:"0 1px 0 gray",height:"1px",width:"88%",marginTop:"20px",marginBottom:"20px"}}></div>
        <div style={{display:"flex",height:"max-content",width:"90%",alignItems:"center",padding:"2px",marginBottom:"10px"}}><img src={personal_icon} alt="personal_icon" style={{marginRight:"40px"}}/> <p style={{marginRight:"190px"}}>Personal</p> <img src={arrow_icon} alt="arrow_icon" style={{transform:personalPopup?"rotate(90deg)":"none",cursor:"pointer"}} onClick={handlePersonalPopup} /></div>
        {personalPopup?<div style={{width:"100%"}}><Personal/></div>:<div></div>}

        <div style={{display:"flex",height:"max-content",width:"90%",alignItems:"center",padding:"2px",marginBottom:"10px"}}><img src={businessInfo_icon} alt="businessInfo_icon" style={{marginRight:"40px"}}/> <p style={{marginRight:"150px",minWidth:"100px"}}>Business Info</p> <img src={arrow_icon} alt="arrow_icon" style={{transform:businessInfoPopup?"rotate(90deg)":"none",cursor:"pointer"}} onClick={handleBusinessInfoPopup} /></div>
        {businessInfoPopup?<div style={{width:"100%"}}><BusinessInfo/></div>:<div></div>}

        <div style={{display:"flex",height:"max-content",width:"90%",alignItems:"center",padding:"2px",marginBottom:"10px"}}><img src={goal_icon} alt="goal_icon" style={{marginRight:"40px"}}/> <p style={{marginRight:"218px"}}>Goal</p> <img src={arrow_icon} alt="arrow_icon" style={{transform:goalPopup?"rotate(90deg)":"none",cursor:"pointer"}} onClick={handleGoalPopup} /></div>
        {goalPopup?<div style={{width:"100%"}}><Goals/></div>:<div></div>}
        
      </div>:<div style={{ marginTop: "2rem" }}>
        <Tabs variant="soft-rectangle" colorScheme="#FFFFFF">
          <TabList ml="4" mr="3">
            <Tab
              backgroundColor="#FFFFFF"
              mr="6"
              borderRadius="8px"
              paddingInline={"5"}
            >
              Personal
            </Tab>
            <Tab
              backgroundColor="#FFFFFF"
              mr="6"
              borderRadius="8px"
              paddingInline={"5"}
            >
              Business Info
            </Tab>
            <Tab
              backgroundColor="#FFFFFF"
              mr="6"
              borderRadius="8px"
              paddingInline={"5"}
            >
              Goals
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Personal />
            </TabPanel>
            <TabPanel>
              <BusinessInfo />
            </TabPanel>
            <TabPanel>
              <Goals />
            </TabPanel>
            <TabPanel>
              <Verification />
            </TabPanel>
            <TabPanel>
              <Services />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>}
    </div>
  );
};

export default Profile;
