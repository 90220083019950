import React, { useContext, useRef, useState } from "react";

// chakra imports
import {
  Box,
  Flex,
  Drawer,
  DrawerBody,
  Icon,
  useColorModeValue,
  DrawerOverlay,
  useDisclosure,
  DrawerContent,
  DrawerCloseButton,
  color,
  IconButton,
  VStack,
  Avatar,
  Text,
  Button,
  Divider,
  Image,
  useBreakpointValue,
  Link
} from "@chakra-ui/react";
import { FiMenu } from "react-icons/fi";
import { FaArrowCircleRight, FaPhone, FaWhatsapp } from "react-icons/fa";
import Content from "components/sidebar/components/Content";
import {
  renderThumb,
  renderTrack,
  renderView
} from "components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import PropTypes from "prop-types";
import { Fade, ScaleFade, Slide, SlideFade, Collapse } from '@chakra-ui/react'
// Assets
import { IoMenuOutline } from "react-icons/io5";
import whatsapp from "../../assets/img/dashboards/whatsap.png"
import whatsapp2x from "../../assets/img/dashboards/whatsapp2x.png"
import reqcall from "../../assets/img/dashboards/reqcall.png"
import reqcall2x from "../../assets/img/dashboards/reqcall2x.png"
import logo from "assets/img/layout/clint-profile.jpg";
import notifIcon2 from "../../assets/img/dashboards/notifIcon2.png"
import notifIcon from "../../assets/img/dashboards/notifIcon.png"
import notifIcon2x from "../../assets/img/dashboards/notifIcon2x.png"
import mailIcon from "../../assets/img/dashboards/mailIcon.png"
import mailIcon2x from "../../assets/img/dashboards/mailIcon2x.png"
import { MdCall, MdNotifications, MdOutlineDashboard, MdOutlineEmail, MdOutlineLogout, MdOutlineMessage, MdOutlinePerson, MdOutlineRecommend, MdOutlineSettings, MdOutlineSupport } from "react-icons/md";
import { SidebarContext } from "contexts/SidebarContext";
import useUserDisplayName from "components/hooks/useUserDisplayName";
import BizowlLogo from "../../assets/img/dashboards/logo-of-BIZOWL--Business-Services.png"
import BizowlLogo2x from "../../assets/img/dashboards/logo-of-BIZOWL--2x.png"
import BizowlLogo3x from "../../assets/img/dashboards/logo-of-BIZOWL--3x.png"
import { auth } from "config/firebase";
import { db } from "config/firebase";
function Sidebar(props) {
  const { routes } = props;
  const { isOpen, onClose } = useContext(SidebarContext);
  // Chakra Color Mode
  let sidebarBg = useColorModeValue("#6e9bfe", "75%");
  let sidebarMargins = "0px";
 
  // SIDEBAR
  return (
    <>
      {
        !props.isCollapse && (
          <Collapse in={!props.isCollapse} animateOpacity>
          <Box
            display={{ sm: "none", xl: "block" }}
            w="100%"
            position="fixed"
            minH="100%"
            
          >
            <Box
              bg={sidebarBg}
              w={props.isCollapse ? "60px" : "260px"}
              h="100vh"
              m={sidebarMargins}
              minH="100%"
              transition="all 2s ease-in-out" 
              // overflowX="hidden"
              
            >

              <Scrollbars
                autoHide
                renderTrackVertical={renderTrack}
                renderThumbVertical={renderThumb}
                renderView={renderView}
              >
                <Content routes={routes} isCollapse={props.isCollapse} setIsCollapse={props.setIsCollapse} />
              
              </Scrollbars>
            </Box>
          </Box>
          </Collapse>
        )

      }
      {
        props.isCollapse && (
          <Collapse in={props.isCollapse} animateOpacity>
          <Box
          display={{ sm: "none", xl: "block" }}
          w="100%"
          position="fixed"

        >
          <Box
            bg={sidebarBg}
            transition="width 2s ease-in-out, margin 2s ease-in-out, opacity 2s ease-in-out"
            w={props.isCollapse ? "60px" : "260px"}
            h="100vh"
            m={sidebarMargins}
            top="21px"
            left="51px"
            minH="100%"
            // overflowX="hidden"
            marginLeft="15px"
            marginTop="15px"
            marginBottom="15px"
            borderRadius="25px"
          >

            <Scrollbars
              autoHide
              renderTrackVertical={renderTrack}
              renderThumbVertical={renderThumb}
              renderView={renderView}
            >
              <Content routes={routes} isCollapse={props.isCollapse} setIsCollapse={props.setIsCollapse} />
            </Scrollbars>
          </Box>
        </Box>
        </Collapse>
        )
  

      }
    </>
  );
}

// FUNCTIONS
export function SidebarResponsive({ routes, isOpen, onClose }) {
  const { displayName, isLoading } = useUserDisplayName(db, auth);
  let sidebarBackgroundColor = useColorModeValue("white", "navy.800");
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  // const { routes } = props;
  // const { displayName } = useUserDisplayName();
  // const { isOpen, onClose } = useContext(SidebarContext);
  

  return (
    <Flex display={{ sm: "flex", xl: "none" }} alignItems="center">
    <Drawer isOpen={isOpen} onClose={onClose} placement="left" size="full">
      <DrawerOverlay />
      <DrawerContent bg={sidebarBackgroundColor} >
        <DrawerCloseButton position="absolute" left="8px" my={3}  _focus="none"/>
        <DrawerBody>
          <VStack spacing={4} align="center" w="full" mt="4">
            {/* Top Row with Logo and Icons */}
            <Flex w="full" justify="space-between" align="center">
              {/* <DrawerCloseButton ml={2} mr={3} /> */}
              <Image src={BizowlLogo3x}  ml={5} w={"44"}  />
              <Flex>
                {/* <Icon as={MdOutlineEmail} boxSize={5} mx={2} /> */}
                <Image src={mailIcon2x} mr={3}  h={4}  />
                <Image src={notifIcon2x} h={5} />
                
                {/* <Icon as={MdNotifications} boxSize={5} mx={2} /> */}
              </Flex>
            </Flex>

            {/* User Avatar and Name */}
            <Avatar size="md" name={displayName}   />
            <Text fontWeight="bold" >{displayName}</Text>
            {/* <Divider /> */}

            {/* Menu Items from routes */}
            {routes.map((route, index) => (
              
              <Box key={index} w="full" px={4} >
                <Flex align="center" justify="space-between" w="full" my={2}>
                {route.isExternal ? (
              <Link href={route.externalUrl} isExternal style={{ textDecoration: "none" }}>
                <Flex align="center"  color={window.location.pathname ===  route.path ? "#1C6ED0" : "inherit"}>
                  <Image src={route.mobIcon} mr={6} alt="" />
                  <Text fontWeight={600}>{route.name}</Text>
                 
                </Flex>
              </Link>
            ) : (
                <Link href={route.layout + route.path} key={route.name} style={{ textDecoration: "none" }}>
                  <Flex align="center"  color={window.location.pathname === route.layout + route.path ? "#1C6ED0" : "inherit"}>
                    {/* <Icon as="route.icon"/> */}
                   
                    <Image src={route.mobIcon}  mr={6} alt=""/>
                    <Text fontWeight={600} >{route.name}</Text>
                   
                  </Flex>
                  </Link>
            )}
                </Flex>
                
                <Divider  border="1px solid rgba(0, 0, 0, 0.35)" 
                // boxShadow="0px 1.5px 1.5px rgba(0, 0, 0, 0.25)"
                 />
              </Box>
            ))}

            {/* Contact Us Section */}
            {/* <Button w="6/12" textAlign="left"  colorScheme="blue" mt={4}>
              Contact Us
            </Button> */}
              {/* <Flex  alignSelf="start"  bg="#D7E9FD"  px={4} py="2" rounded="full" mt="auto" >
                <Text color="#1C6ED0" textAlign="left" fontWeight={600} px={3}>Contact Us</Text>
              </Flex>  */}
             <Box
             
  width="auto" // Keeps the width limited to the content
  bg="#D7E9FD" // Background color
 paddingX="5"
 paddingY="1.5" // Padding around the text
  borderRadius="8px" // Rounded corners
  color="#1C6ED0" // Text color
  // fontFamily="'Cabin', sans-serif" // Font family
  fontWeight="semibold"
  fontSize="16px"
  lineHeight="19px"
  marginTop="auto"
  
  marginBottom="5px"
  rounded="full"
  // Aligns text within the box to the left
  alignSelf="start" // Moves the entire box to the left of the container
>
  Contact Us
              </Box>
            <Flex w="full" justify="flex-start" mb={4}>
              {/* WhatsApp */}
              <Flex direction="column" align="center" mr={8} ml={3}>
              <Link href="https://wa.me/message/MR4KKWOY4H62O1">
                <Image src={whatsapp2x} mb={1} h={10}/>
                </Link>
                {/* <Icon as={FaWhatsapp} boxSize={6} mb={1} /> */}
                <Text fontSize="xs" textAlign="center">
                  Connect on <br /> WhatsApp
                </Text>
              </Flex>

              {/* Request Callback */}
              <Flex direction="column" align="center">
              <Link href="https://www.bizzowl.com/contactUs">
              <Image src={reqcall2x} mb={1} h={10}/>
              </Link>
                {/* <Icon as={FaPhone} boxSize={6} mb={1} /> */}
                <Text fontSize="xs" textAlign="center">
                  Request <br /> Callback
                </Text>
               
              </Flex>
            </Flex>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  </Flex>
  );
}


// PROPS

Sidebar.propTypes = {
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.string
};

export default Sidebar;
